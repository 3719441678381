import axios from "axios";
import addDeleteGetSessionStorage from "../global-modules/addDeleteGetSessionStorage";
import { storageKeys } from "../global-modules/helper/Enum";

export const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let token = addDeleteGetSessionStorage(
      storageKeys.ADMIN_TOKEN,
      null,
      "get",
      "single"
    );
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
 
);
// Add a response interceptor if needed
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    
    return response;
  },
  (error) => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, like redirecting to login
      localStorage.removeItem("fixello_data");
      localStorage.removeItem("adminaccessToken");

      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
