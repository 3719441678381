import moment from "moment";

export const checkImageOrPdf = (url) => {
  if (url?.includes(".pdf")) {
    return "pdf";
  } else {
    return "image";
  }
};

export const globalSearch = (data, searchQuery) => {
  
  const containsSearchQuery = (item) => {
    const recursiveSearch = (obj) => {
      return Object.values(obj).some((value) => {
        if (typeof value === "object" && value !== null) {
          return recursiveSearch(value);
        }
        return String(value).toLowerCase().includes(searchQuery.toLowerCase());
      });
    };

    return recursiveSearch(item);
  };
  return data.filter(containsSearchQuery);
  

};

export const changeDateFormat = (date = "", type = "default") => {
  let dates = "";
  
  try {
    if (!date) return "-";
    if (type == 'default') {
      dates = moment(date).format('ll');
    }
    if(type === "dateAndTime"){
      dates = moment(date).format('llll');

    }
  } catch (e) {
    return e?.message;
  }
  return dates;
};

export const timeFormat = (timeStr) => {
  try {
    if (!timeStr) return timeStr;
        let timeParts = timeStr.split(":"); // Splitting the time string by colon
        return timeParts[0] + ":" + timeParts[1] + " " + timeParts[2].split(" ")[1];
  } catch (e) {
    return timeStr;
  }
}

export const buildAddress = (address = {}) => {
  
  return address = `${address?.address}, ${address?.zone_no ? address?.zone_no+",":""} ${address?.area},  `;
};

export const statusLabelButton = (status) => {
  if(!status) return "";
  let statusText = "";
  let className = ""; 
  switch(status.toLowerCase()) {
      case "pending":
          statusText = "Pending";
          className = "warning";
          break;
      case "rejected":
      case "cancelled":
          statusText = "Rejected";
          className = "danger";
          break;
      case "completed":
          // statusText = "Mark as completed at customer's end";
          statusText = "Completed";
          className = "success";
          break;
      default:
          statusText = status;
          className = "danger"; // default to success if status is unknown
          break;
  }
  
  return <span className={`chips ${className}`}>{statusText}</span>;
};




export const strFormat = (inputStr, type) => {
  try {
    
    let all = type.split("|");
    if (all.includes("trim")) {
      inputStr = inputStr.trim();
    }

    if (all.includes("expiry_date") && inputStr) {
      //  inputStr = inputStr.replace(/\//g, "").substring(0, 2) +
      //   (inputStr.length > 2 ? '/' : '') +
      //   inputStr.replace(/\//g, "").substring(2, 4);
      inputStr = inputStr.replace(/\D/g, "");
      inputStr =
        inputStr.replace(/\//g, "").substring(0, 2) +
        (inputStr.length > 2 ? "/" : "") +
        inputStr.replace(/\//g, "").substring(2, 4);
    }

    if (all.includes("number")) {
      inputStr = inputStr.replace(/[^0-9]/g, "");
    }
    if (all.includes("text")) {
      inputStr = inputStr.replace(/\d/g, ""); // This regex replaces all digits (numbers) with an empty string.
    }
    for (const formatter of all) {
      const [command, ...args] = formatter.split(":");
      switch (command) {
        case "max":
          const maxLength = parseInt(args[0]);
          if (inputStr.length > maxLength) {
            inputStr = inputStr.slice(0, maxLength);
          }
          break;
        default:
          break;
      }
    }
    inputStr = inputStr ? inputStr.replace(/^\s+/, "") : "";
    if (all.includes("count")) {
      inputStr = inputStr ? inputStr.replace(/<[^>]*>/g, '').trim() : "";
      inputStr = inputStr ? inputStr.length : 0; // This regex replaces all digits (numbers) with an empty string.
    }
  } catch (e) { console.log(e) }
  return inputStr;
};