import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "primereact/avatar";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Item } from "primereact";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";

// images
import dawnArrowBlack from "../../../assets/images/structure/down-arrow.svg";
import logoTop from "../../../assets/images/logo/header-logo.png";
import userIcon from "../../../assets/images/structure/user-white.svg";
import { InputSwitch } from "primereact/inputswitch";
import { storageKeys } from "../../../global-modules/helper/Enum";
import addDeleteGetSessionStorage from "../../../global-modules/addDeleteGetSessionStorage";

// other
import "./header.scss";

import ChangePasswordSidebar from "./ChangePasswordSidebar";
import OnlineOfflineDialog from "./OnlineOfflineDialog";
import {
  getAdminNotificationApi,
  getAdminStatusApi,
  getAndUpdateNotificationApi,
} from "../../../service/auth.service";
import { toast } from "react-toastify";
import socket from "../../../utils/socket";
import { Spinner } from "react-bootstrap";
import tone from "../../../assets/notification.mp3";
import NotificationPopup from "./popup";

const Header = ({ role, data }) => {
  const navigate = useNavigate();
  const [nPopup, setNPopup] = useState();
  const audio = new Audio(tone);
  // const audio = new Audio("./src\assets\notification.mp3")
  const menu = useRef(null);
  let ItemRole =
    role === "super_admin"
      ? [
          {
            label: "Change Password",
            command: () => {
              setChangePassword(true);
            },
          },
          {
            label: "Logout",
            command: () => {
              setlogOutDialog(true);
            },
          },
        ]
      : [
          {
            label: "Logout",
            command: () => {
              setlogOutDialog(true);
            },
          },
        ];

  const items = [
    {
      command: () => {
        // toast.current.show({
        //   severity: "info",
        //   summary: "Info",
        //   detail: "Item Selected",
        //   life: 3000,
        // });
      },
      template: (item, options) => {
        return (
          <button
            onClick={(e) => options.onClick(e)}
            className="w-full p-link flex align-items-center py-2 px-3 text-color hover:surface-200 border-noround"
          >
            <Avatar
              image="https://fixello-assets-new.s3.me-south-1.amazonaws.com/company_logo1708590135185.png"
              className="mr-2"
              shape="circle"
            />
            <div className="flex flex-column align">
              <span className="font-bold">{data?.first_name}</span>
              <span className="text-sm">{data?.last_name}</span>
            </div>
          </button>
        );
      },
    },
    { separator: true },
    ...ItemRole,
  ];

  const [checkedMode, setCheckedMode] = useState(true);
  const [adminOnline, setAdminOnline] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [logOutDialog, setlogOutDialog] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [pageQuery, setPageQuery] = useState({ page: 1, limit: 10 });
  const [notificationCount, setNotificationCount] = useState(0);
  const [nloading, setNloading] = useState(false);
  const [nCount, setNCount] = useState(0);
  const handelcheckedMode = () => {
    setAdminOnline(true);
  };

  const logout = () => {
    addDeleteGetSessionStorage(
      storageKeys.ADMIN_TOKEN,
      null,
      "delete",
      "single"
    );
    navigate("/");
  };
  const getAdminStatus = async () => {
    try {
      let res = await getAdminStatusApi();
      if (res?.data?.data?.status === "online") {
        setCheckedMode(true);
      } else {
        setCheckedMode(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notificationListRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationListRef.current &&
        !notificationListRef.current.contains(event.target)
      ) {
        setNotificationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationListRef]);

  const handleNotification = (nData) => {
    if (!nData?.viewId?.includes(data?.id)) {
      UpdateNotificationCount(nData?.id);
    }
    let navScreen = "";
    if (nData?.click_action === "booking_detail") {
      navScreen = "order-management-view/";
    } else {
      navScreen = "manage-sp-detail/";
    }
    navigate(navScreen + nData?.action_id);
    setNotificationOpen(false);
  };
  const getNotificationData = (nData) => {
    audio.play();
    if (nData) {
      setNotificationData((prev) => {
        return [nData, ...prev];
      });
      setNCount((prev) => {
        return prev + 1;
      });
    }
    toast.success(
      <div onClick={() => handleNotification(nData)}>
        <p>{nData?.title}</p>
        <p>{nData?.body}</p>
      </div>,
      {
        autoClose: 5000,
      }
    );
  };

  useEffect(() => {
    getAdminStatus();

    setNPopup(true);
    if (role === "super_admin") {
      socket.on("notification", getNotificationData);
    } else if (role === "operation") {
      socket.on("operator", getNotificationData);
    } else if (role === "sales") {
      socket.on("sales", getNotificationData);
    }
    return () => {
      if (role === "super_admin") {
        socket.off("notification", getNotificationData);
      } else if (role === "operation") {
        socket.off("operator", getNotificationData);
      } else if (role === "sales") {
        socket.off("sales", getNotificationData);
      }
    };
  }, []);
  const getNotificationDataFromApi = async () => {
    try {
      setNloading(true);
      let res = await getAdminNotificationApi({
        page: pageQuery?.page,
        limit: pageQuery?.limit,
      });

      setNotificationData([...notificationData, ...res?.data?.data?.rows]);
      setNotificationCount(res?.data?.data?.count);
    } catch (error) {
      console.log(error);
    } finally {
      setNloading(false);
    }
  };
  const loadMoreData = () => {
    if (nloading) return;
    if (notificationData?.length != notificationCount) {
      setPageQuery({ ...pageQuery, page: pageQuery?.page + 1 });
    }
  };
  const UpdateNotificationCount = async (nid) => {
    try {
      if (nid) {
        let modifyData = notificationData?.map((nData) => {
          if (nData?.id === nid) {
            nData.viewId = [data?.id];
          }
          return nData;
        });

        setNotificationData(modifyData);
      }
      let res = await getAndUpdateNotificationApi({ nId: nid ? [nid] : [] });
      setNCount(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotificationDataFromApi();
    UpdateNotificationCount();
  }, [pageQuery]);

  return (
    <>
      <NotificationPopup open={nPopup} setOpen={() => setNPopup(false)} />
      <div className="header-wrapper">
        <div className="header">
          <div className="container">
            <div className="header-flex">
              <div className="header-left">
                <div className="header__logo">
                  <Link to="/dashboard">
                    <img src={logoTop} alt="logo" width="45px" />
                  </Link>
                </div>
              </div>
              <div className="header-right">
                <div className="flex align-items-center headerOnOff">
                  <p
                    className="p3 mb-0 mt-0 text-white mr-2"
                    style={{ fontWeight: "600" }}
                  >
                    Offline
                  </p>
                  <InputSwitch
                    checked={checkedMode}
                    onChange={handelcheckedMode}
                  />
                  <p
                    className="p3 mb-0 mt-0 text-white ml-2"
                    style={{ fontWeight: "600" }}
                  >
                    Online
                  </p>
                </div>
                <div className="top-toolbar-right-item pl-3">
                  <div
                    className="currency-drop"
                    onClick={(e) => {
                      setNotificationOpen(!notificationOpen);
                      // UpdateNotificationCount(notificationData);
                    }}
                  >
                    <i
                      className="pi pi-bell"
                      style={{ fontSize: "1.5rem", color: "#fff" }}
                    />
                    {nCount ? <Badge value={nCount} /> : ""}
                  </div>
                  {notificationOpen && (
                    <div
                      ref={notificationListRef}
                      className="notification-list "
                    >
                      {notificationData?.map((nData) => {
                        return (
                          // read
                          <div
                            className={`notification-list-item ${
                              nData?.viewId?.includes(data?.id) ? "read" : ""
                            }`}
                            onClick={() => handleNotification(nData)}
                          >
                            <p className="p2 font-bold mb-0">{nData?.title} </p>
                            <p className="p2 mb-0">{nData?.body} </p>
                          </div>
                        );
                      })}
                      {notificationData?.length
                        ? notificationData?.length !== notificationCount && (
                            <div
                              className="notification-list-more"
                              onClick={loadMoreData}
                            >
                              {nloading ? <Spinner size="sm" /> : "More"}
                            </div>
                          )
                        : null}
                    </div>
                  )}
                </div>
                <div className="header__user">
                  <Menu model={items} popup ref={menu} />
                  <span onClick={(e) => menu.current.toggle(e)}>
                    <img src={userIcon} alt="user" className="icon24" />
                    <img
                      src={dawnArrowBlack}
                      alt="arrow-down"
                      className="down-arrow"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordSidebar
        open={changePassword}
        setOpen={setChangePassword}
      />
      <OnlineOfflineDialog
        open={adminOnline}
        setOpen={setAdminOnline}
        status={checkedMode}
        setStatus={setCheckedMode}
      />

      <Dialog
        header="Logout"
        className="dialogWidth400 headetPadding"
        visible={logOutDialog}
        style={{ width: "400px" }}
        onHide={() => setlogOutDialog(false)}
        draggable={false}
        footer={({ hide }) => (
          <div className="dialogFooter-2 p2 dilogbtns w-100">
            <Button
              className="btn btn-gray w-100"
              onClick={() => {
                setlogOutDialog(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn btn-orange w-100"
              onClick={() => {
                setlogOutDialog(false);
                logout();
              }}
            >
              Yes
            </Button>
          </div>
        )}
      >
        <div className="addformDialog pt-3">
          <p className="p1 mb-3 mt-0" style={{ textAlign: "center" }}>
            Are you sure want to Logout?
          </p>
        </div>
      </Dialog>
      <Outlet />
    </>
  );
};

export default Header;
